/**
 * ProductCard Component
 * Displays a product card with image and details according to Figma design
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import styles
import {
  // Common styles
  commonProductCardStyles,
  commonProductImageStyles,
  commonContentContainerStyles,
  commonProductNameStyles,
  commonProductPriceStyles,
  
  // Mobile styles
  mobileProductCardStyles,
  mobileProductImageStyles,
  mobileContentContainerStyles,
  mobileProductNameStyles,
  mobileProductPriceStyles,
  
  // Tablet styles
  tabletProductCardStyles,
  tabletProductImageStyles,
  tabletContentContainerStyles,
  tabletProductNameStyles,
  tabletProductPriceStyles,
  iPadMiniProductCardStyles,
  iPadMiniProductImageStyles,
  iPadAirProductCardStyles,
  iPadAirProductImageStyles,
  iPadProProductCardStyles,
  iPadProProductImageStyles,
  
  // Desktop styles
  desktopProductCardStyles,
  desktopProductImageStyles,
  desktopContentContainerStyles,
  desktopProductNameStyles,
  desktopProductPriceStyles,
} from './styles';

const ProductCard = ({ 
  product, 
  deviceType, 
  tabletType, 
  showPrice, 
  brandShowPrice, 
  sx = {} 
}) => {
  const navigate = useNavigate();
  
  // Función para manejar el clic en el producto
  const handleProductClick = () => {
    navigate(`/products/${product.id}`);
  };
  
  // Get device-specific styles
  const getProductCardStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductCardStyles, ...mobileProductCardStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadMiniProductCardStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadAirProductCardStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadProProductCardStyles };
      }
      return { ...commonProductCardStyles, ...tabletProductCardStyles };
    }
    
    return { ...commonProductCardStyles, ...desktopProductCardStyles };
  };
  
  const getProductImageStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductImageStyles, ...mobileProductImageStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadMiniProductImageStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadAirProductImageStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadProProductImageStyles };
      }
      return { ...commonProductImageStyles, ...tabletProductImageStyles };
    }
    
    return { ...commonProductImageStyles, ...desktopProductImageStyles };
  };
  
  const getContentContainerStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonContentContainerStyles, ...mobileContentContainerStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonContentContainerStyles, ...tabletContentContainerStyles };
    }
    
    return { ...commonContentContainerStyles, ...desktopContentContainerStyles };
  };
  
  const getProductNameStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductNameStyles, ...mobileProductNameStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonProductNameStyles, ...tabletProductNameStyles };
    }
    
    return { ...commonProductNameStyles, ...desktopProductNameStyles };
  };
  
  const getProductPriceStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductPriceStyles, ...mobileProductPriceStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonProductPriceStyles, ...tabletProductPriceStyles };
    }
    
    return { ...commonProductPriceStyles, ...desktopProductPriceStyles };
  };
  
  // Apply custom styles
  const cardStyles = {
    ...getProductCardStyles(),
    ...sx
  };
  
  // Formatear el precio con el símbolo de la moneda
  const formatPrice = (price, currency = '$') => {
    return `${currency}${price.toFixed(2)}`;
  };

  // Get the primary image from the images array
  const getProductImage = () => {
    if (product.images && product.images.length > 0) {
      return product.images[0];
    }
    
    // Fallback for legacy 'image' property
    return product.image || '';
  };
  
  // Determine if price should be displayed with brandShowPrice as priority
  // brandShowPrice viene directamente del flag show_price de la marca
  const shouldShowPrice = brandShowPrice !== undefined ? brandShowPrice : showPrice;
  
  // Para depuración: verificar valores de props y cálculo de shouldShowPrice
  console.log('ProductCard - Props:', { 
    productName: product.name,
    brandShowPrice, 
    showPrice, 
    shouldShowPrice 
  });
  
  return (
    <Box 
      sx={cardStyles} 
      onClick={handleProductClick}
    >
      <Box 
        component="img"
        src={getProductImage()} 
        alt={product.name} 
        sx={getProductImageStyles()}
      />
      <Box sx={getContentContainerStyles()}>
        <Typography sx={getProductNameStyles()}>
          {product.name}
        </Typography>
        {shouldShowPrice && product.price && (
          <Typography sx={getProductPriceStyles()}>
            {formatPrice(product.price)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']),
  showPrice: PropTypes.bool,
  brandShowPrice: PropTypes.bool,
  sx: PropTypes.object
};

ProductCard.defaultProps = {
  tabletType: 'generic',
  showPrice: false,
  sx: {}
};

export default memo(ProductCard); 