import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import ProductGrid from './components/ProductGrid';
import FeaturedCard from './components/FeaturedCard';
import gafProducts from '../../../../data/gafProducts.json';
import {
  mobileContainerStyles,
  mobileFeaturedImageStyles,
  mobileProductGridStyles
} from './styles/mobile';
import {
  tabletContainerStyles,
  tabletFeaturedImageStyles,
  tabletProductGridStyles
} from './styles/tablet';
import {
  desktopContainerStyles,
  desktopFeaturedImageStyles,
  desktopProductGridStyles
} from './styles/desktop';

const FeaturedBrandShowcase = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { brands, products } = gafProducts;
  const gafBrand = brands[0]; // GAF is the first brand
  const featuredProducts = products.slice(0, 6); // Get first 6 products

  // Para depuración: verificar si gafBrand.show_price existe y su valor
  console.log('FeaturedBrandShowcase - gafBrand.show_price:', gafBrand.show_price);

  const containerStyles = isDesktop
    ? desktopContainerStyles
    : isTablet
      ? tabletContainerStyles
      : mobileContainerStyles;

  const featuredImageStyles = isDesktop
    ? desktopFeaturedImageStyles
    : isTablet
      ? tabletFeaturedImageStyles
      : mobileFeaturedImageStyles;

  const productGridStyles = isDesktop
    ? desktopProductGridStyles
    : isTablet
      ? tabletProductGridStyles
      : mobileProductGridStyles;

  if (!gafBrand) return null;

  return (
    <Box sx={containerStyles}>
      <Box sx={featuredImageStyles}>
        <FeaturedCard
          image={gafBrand.featuredImages.web}
          logo={gafBrand.logo}
          title={gafBrand.name}
          subtitle="Premium Roofing Solutions"
          brandId="brand_gaf"
          showPrice={gafBrand.show_price}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <ProductGrid 
          products={featuredProducts} 
          sx={productGridStyles} 
          showPrice={gafBrand.show_price}
        />
      </Box>
    </Box>
  );
};

export default FeaturedBrandShowcase; 