import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const FeaturedCard = ({ 
  image, 
  logo, 
  title, 
  subtitle, 
  brandId,
  showPrice
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Para depuración: verificar si showPrice existe y su valor
  console.log('FeaturedCard - showPrice:', { brandId, showPrice });

  const handleClick = () => {
    navigate(`/brands/${brandId}`);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '400px', sm: '100%' },
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
          zIndex: 1
        }
      }}
      onClick={handleClick}
    >
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 2
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Brand Logo"
            sx={{
              width: 60,
              height: 60,
              borderRadius: '50%',
              objectFit: 'contain'
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                textShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'white',
                textShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          sx={{
            width: 'calc(100% - 32px)',
            mx: 2,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            bgcolor: '#E96D25',
            borderRadius: '24px',
            color: 'white',
            '&:hover': {
              bgcolor: '#D15F1F'
            }
          }}
        >
          {t('featuredCard.orderNow')}
        </Button>
      </Box>
    </Box>
  );
};

FeaturedCard.propTypes = {
  image: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  showPrice: PropTypes.bool
};

FeaturedCard.defaultProps = {
  showPrice: false
};

export default FeaturedCard; 